/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Summary} = _components;
  if (!Summary) _missingMdxReference("Summary", true);
  return React.createElement(React.Fragment, null, React.createElement(Summary, null, "Hectiq is looking for an enthusiast intern to work on a specific artificial intelligence project."), "\n", React.createElement(_components.h2, null, "Your responsabilities"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Bring the client's idea into a realistic project that leaverages AI technologies"), "\n", React.createElement(_components.li, null, "Develop and increment AI models"), "\n", React.createElement(_components.li, null, "Produce and ship high quality code"), "\n", React.createElement(_components.li, null, "Document your work"), "\n"), "\n", React.createElement(_components.h2, null, "Typical profile"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Good knowledge of Python and popular scientific packages (numpy, pandas, scipy)"), "\n", React.createElement(_components.li, null, "Knowledge of pytorch or tensorflow"), "\n", React.createElement(_components.li, null, "Good writting and oral communication skills"), "\n"), "\n", React.createElement(_components.h2, null, "Extra assets (or what you'll learn)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A deep knowledge of a specific AI domain: object detection, GNN, time series analysis, bayesian models, ..."), "\n", React.createElement(_components.li, null, "Deploy models on AWS/GCloud/Azure"), "\n", React.createElement(_components.li, null, "Knowledge of modern packages for frontend (React, D3.js, bokeh, ...) or backend (nodejs, fastapi, ...)"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
